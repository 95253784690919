import WebFont from "webfontloader";
import fitty from "fitty";

export default WebFont.load({
  google: {
    families: [
      "Playfair Display:400,400i,900,900i",
      "PT Sans Narrow:400,400i,700&display=swap"
    ]
  },
  active() {
    fitty('.fitty',{
      multiLine: true,
      minSize: 12,
      maxSize: 1200
    });
  }
});
