import type { AlpineComponent } from 'alpinejs'
import Glide, { Swipe, Controls, Breakpoints, Keyboard } from '@glidejs/glide/dist/glide.modular.esm';
import '@glidejs/glide/dist/css/glide.theme.css';

// if better types get needed check
// https://github.com/alpinejs/alpine/issues/2199#issuecomment-1809892127

export function GalleryPopup(
  modalRef: string,
): AlpineComponent<{
  ref: undefined | HTMLDialogElement
  slider: Glide|null
  init(): void
  open(): void
  close(): void
}> {

  return {
    ref: undefined,
    slider: null,

    init() {
      this.ref = this.$refs[modalRef] as HTMLDialogElement
    },

    open() {
      this.ref?.showModal()

      if (this.slider) {
        this.$nextTick(() => {
          this.slider.update()
        })
      } else {
        this.$nextTick(() => {
          this.slider = new Glide(this.$refs.glide, {
            gap: 0,
            perView: 1,
          })

          this.slider.mount({ Swipe, Controls, Breakpoints, Keyboard })
        })

      }
    },

    close() {
      this.ref?.close()
    }
  }
}
