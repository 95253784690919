import Alpine from 'alpinejs'
import focus from '@alpinejs/focus'
import { BlogSlider } from './components/BlogSlider'
import { ImageGallery } from './components/ImageGallery'
import { Modal } from './components/Modal'
import { GalleryPopup } from './components/GalleryPopup'

import "@js/config/plugins"; // Misc JS plugins
import "@js/config/fontLoading"; // Load fonts

window.Alpine = Alpine
Alpine.plugin(focus)

Alpine.data('blogSlider', BlogSlider)
Alpine.data('imageGallery', ImageGallery)
Alpine.data('modal', Modal)
Alpine.data('galleryPopup', GalleryPopup)

window.lazySizes.init();

Alpine.start()
