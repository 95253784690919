import type { AlpineComponent } from 'alpinejs'
import Cookies from 'js-cookie'

export function Modal(
  modalRef: string,
  cookieName: string | undefined | null,
  enableTimer: boolean = false,
  delay: number = 0,
): AlpineComponent<{
  ref: undefined | HTMLDialogElement
  isOpen: boolean
  init(): void
  openModal(): void
  closeModal(): void
  setCookie(): void
}> {
  return {
    ref: undefined,
    isOpen: false,

    init() {
      this.ref = this.$refs[modalRef] as HTMLDialogElement

      if (!cookieName) return

      const cookie = Cookies.get(cookieName)

      if (enableTimer && cookie != '1') {
        setTimeout(() => {
          this.openModal()
        }, delay * 1000)
      }

      if (this.ref) {
        this.ref.addEventListener('close', () => {
          this.setCookie()
          this.isOpen = false
        })
      }
    },

    openModal() {
      this.ref?.showModal()
      this.isOpen = true
    },

    closeModal() {
      this.ref?.close()
      this.isOpen = false
      this.setCookie()
    },

    setCookie() {
      if (cookieName) {
        Cookies.set(cookieName, '1', { expires: 90 })
      }
    },
  }
}
