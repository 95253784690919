import type { AlpineComponent } from 'alpinejs'
import Glide, { Swipe, Controls, Breakpoints, Keyboard } from '@glidejs/glide/dist/glide.modular.esm';
import '@glidejs/glide/dist/css/glide.theme.css';

// if better types get needed check
// https://github.com/alpinejs/alpine/issues/2199#issuecomment-1809892127

export function BlogSlider(
  perView: number,
): AlpineComponent<{
  perView: number
  init(): void
  discard(): void,
}> {
  return {
    perView: perView || 3,

    init() {
      const slider = new Glide('.glide', {
        gap: 0,
        perView: this.perView,
        focusAt: 0,
        bound: true,
        rewind: false,
        dragThreshold: 80,
        peek: {
          before: 0,
          after: 100
        },
        breakpoints: {
          500: {
            perView: 1
          },
          768: {
            perView: 1.5
          },
          900: {
            perView: 2
          },
          1200: {
            perView: 2.5
          }
        }
      })

      slider.mount({ Swipe, Controls, Breakpoints, Keyboard })
    },
  }
}
