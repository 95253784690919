import type { AlpineComponent } from 'alpinejs'
import Glide, { Swipe, Controls, Breakpoints, Keyboard } from '@glidejs/glide/dist/glide.modular.esm';
import '@glidejs/glide/dist/css/glide.theme.css';

// if better types get needed check
// https://github.com/alpinejs/alpine/issues/2199#issuecomment-1809892127

export function ImageGallery(): AlpineComponent<{
  init(): void
}> {
  return {
    init() {
      const slider = new Glide(this.$el.querySelector('.glide'), {
        gap: 0,
        perView: 1,
      })

      slider.mount({ Swipe, Controls, Breakpoints, Keyboard })
    },
  }
}
